import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/send-message",
    component: () => import("@/views/layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/settings",
        name: "settings",
        component: () => import("@/views/Settings.vue"),
        meta: {
          pageTitle: "設置",
          breadcrumbs: ["設置"],
        },
      },
      {
        path: "/send-message",
        name: "send-message",
        component: () => import("@/views/SendMessages.vue"),
        meta: {
          pageTitle: "發送訊息",
          breadcrumbs: ["發送訊息"],
        },
      },
      {
        path: "/send-records",
        name: "send-records",
        component: () => import("@/views/SendRecords.vue"),
        meta: {
          pageTitle: "發送記錄",
          breadcrumbs: ["發送記錄"],
        },
      },
      {
        path: "/message-templates",
        name: "message-templates",
        component: () => import("@/views/MessageTemplates.vue"),
        meta: {
          pageTitle: "我的文案",
          breadcrumbs: ["我的文案"],
          customHeader: true,
        },
      },
      {
        path: "message-template/add",
        name: "message-template-add",
        component: () => import("@/views/MessageTemplateAdd.vue"),
        meta: {
          pageTitle: "新增文案",
          breadcrumbs: ["新增文案"],
        },
      },
      {
        path: "message-statics",
        name: "message-statics",
        component: () => import("@/views/MessageStatics.vue"),
        meta: {
          pageTitle: "訊息數據統計",
          breadcrumbs: ["訊息數據統計"],
        },
      },
      {
        path: "message-tasks",
        name: "message-tasks",
        component: () => import("@/views/MessageTasks.vue"),
        meta: {
          pageTitle: "發送任務列表",
          breadcrumbs: ["發送任務列表"],
        },
      },
      {
        path: "/",
        name: "home",
        component: () => import("@/views/Home.vue"),
        meta: {
          pageTitle: "首頁",
          breadcrumbs: ["首頁"],
        },
      },
    ],
  },
  {
    path: "/",
    children: [
      {
        path: "/register/:invite_code",
        name: "register",
        component: () => import("@/views/auth/Register.vue"),
        meta: {
          pageTitle: "註冊",
        },
      },
      {
        path: "/login",
        name: "login",
        component: () => import("@/views/auth/Password/Index.vue"),
        meta: {
          pageTitle: "登入",
        },
      },
      {
        path: "/set-token/:token",
        name: "set-token",
        component: () => import("@/views/auth/SetToken.vue"),
        meta: {
          pageTitle: "Set Token",
        },
      },
    ],
  },
  {
    path: "/multi-step-sign-up",
    name: "multi-step-sign-up",
    component: () =>
      import("@/views/crafted/authentication/MultiStepSignUp.vue"),
    meta: {
      pageTitle: "Multi-step Sign up",
    },
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // current page view title
  document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`;

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // verify auth token before each page change
  store.dispatch(Actions.VERIFY_AUTH, { token: JwtService.getToken() });

  // before page access check if page requires authentication
  if (to.meta.middleware == "auth") {
    if (store.getters.isUserAuthenticated) {
      next();
    } else {
      next({ name: "login" });
    }
  } else {
    next();
  }

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;
